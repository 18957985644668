import React, { useEffect } from "react";
import Aos from "aos";

import "./ClientSection.css";

export const ClientSection = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="client-section-upper-main-div">
      <h2 className="Client-section-div">
        TaskCraft Benefits and Values for Client
      </h2>

      <div className="client-section-main-div">
        <div className="client-section-first-container">
          <div data-aos="zoom-in">
            <h3>
              <b>Top Rated Software Development Company</b>
            </h3>
            <p className="client-paragraph-content">
              Our technical prowerss, Software consulting capabilities, and
              Software Outsourcing experience helped thousands of clients
              worldwide to excel.
            </p>
            <div className="containers">
              <div className="row first-containers-row">
                <div className="col-6 ">
                  <div className="vl">
                    <div className="client-content">
                      <h2>
                        <b className="client-values">High Retentions</b>
                      </h2>
                      <p className="client-paragraph-content">
                        Dependable Team for long term Partnership
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="vl">
                    <div className="client-content">
                      <h2>
                        <b className="client-values">Fair Pay</b>
                      </h2>
                      <p className="client-paragraph-content-hidden">
                        Satisfied employees tends to stay much longer and help
                        the Company grow
                      </p>
                    </div>
                  </div>
                </div>
                <b className="client-paragraph-heading">
                  Attracting and Retaining Right Talent
                </b>
                <p className="client-paragraph-content-additional">
                  We build a talanted diverse workforce passinate about
                  delivering swift and radical changes
                </p>
                <div className="w-100 d-none d-md-block"></div>
                <div className="col-6 ">
                  <div className="vl">
                    <div className="client-content">
                      <h3>
                        <b className="client-values">100% inhouse Developers</b>
                      </h3>
                      <p className="client-paragraph-content">
                        Complete control and transparent process
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="vl">
                    <div className="client-content">
                      <h2>
                        <b className="client-values-hidden">Buddy System</b>
                      </h2>
                      <p className="client-paragraph-content-hidden">
                        Each Developer is assigned a buddy who is always
                        available to help and guide
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="client-section-second-container">
          <div
            className="client-section-first-sub-container"
            data-aos="zoom-in"
          >
            <h3>
              <b>Software Development Success</b>
            </h3>
            <div className="containers">
              <div className="row first-containers-row">
                <div className="col-6 ">
                  <div className="vl">
                    <div className="client-content">
                      <h3>
                        <b className="client-values">
                          Cost Optimised Development Center
                        </b>
                      </h3>
                      <p className="client-paragraph-content">
                        Save Development cost and invest more on Growing your
                        Business
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="vl">
                    <div className="client-content">
                      <h2>
                        <b className="client-values">Agile</b>
                      </h2>
                      <p className="client-paragraph-content">
                        Upscale and Downscale your team with 1 month notice
                      </p>
                    </div>
                  </div>
                </div>
                <b className="client-paragraph-heading">
                  Meeting Client Expectation
                </b>
                <p className="client-paragraph-content">
                  Through open conversations and radical tansparancy with
                  clients we have to build stronger relationships
                </p>
                <div className="w-100 d-none d-md-block"></div>
                <div className="col-6 ">
                  <div className="vl">
                    <div className="client-content">
                      <h2>
                        <b className="client-values">Bench Strength</b>
                      </h2>
                      <p className="client-paragraph-content">
                        10% Ready Bench for development, safe guarding immediate
                        requirements
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="vl">
                    <div className="client-content">
                      <h1>
                        <b className="client-values">93%</b>
                      </h1>
                      <p className="client-paragraph-content">
                        of Client return for Addtional Project
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
