
import React from "react";

// import { CareersFormUploadContent } from "./careersFormUpload/careersFormUploadContent/CareersFormUploadContent";
// import {CareersFormUpload} from ".
import "../hireDevelopers/hireDevelopersTopSection/HireDevelopersTopSection.css"
import { CareersFormUpload } from "./careersFormUpload/CareersFormUpload";
import { CareersFormUploadContent } from "./careersFormUpload/careersFormUploadContent/CareersFormUploadContent";



export const CareersForm = () => {
  return (
    <>
      <section className="hire-developers-image">
        <div className="hire-developers-main-section">
          <div className="container text-center">
            <div className="row align-items-start">
              <div className="col">
                 <CareersFormUploadContent />
              </div>
              <div className="col">
                <CareersFormUpload />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};