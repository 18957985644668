import React from "react";
import {
  FaFacebook,
  FaInstagramSquare,
  FaLinkedin,
  FaSkype
} from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";

import iso from "../../assets/iso_27001_02.png";
import cmmi5 from "../../assets/CMMI_Level_5.png";
import "./Footer.css";
import { useScroll } from '../../context/ScrollContext';
import { useIndustries } from "../../context/IndustriesContext";
import { useRadio } from "../../context/RadioContext";

const first_column = [
  { id: "f1", title: "Healthcare" },
  { id: "f2", title: "Energy & Gas" },
  { id: "f3", title: "E-commerce" },
  { id: "f4", title: "Transportation & Logistics" },
];
const second_column = [
  { id: "s1", title: "Sports" },
  { id: "s2", title: "Fintech" },
  { id: "s3", title: "On-Demand" },
  { id: "s4", title: "Marketing & Advertising" },
];


const center_first_column = [
  { id: "cf1", title: "Software Development" },
  {
    id: "cf2",
    title: "Infrastructure Management",
  },
  {
    id: "cf3",
    title: "Enterprise Solution",
  },
  {
    id: "cf4",
    title: "Application Services",
  },
];

const center_second_column = [
  { id: "cs1", title: "ROR" },
  { id: "cs2", title: "React.js" },
  { id: "cs3", title: "Ract Native" },
  { id: "cs4", title: "Node.js" },
  { id: "cs5", title: "Typescript" },
];

const center_third_column = [
  { id: "cs6", title: "HTML5" },
  { id: "cs7", title: "MEAN" },
  { id: "cs8", title: "MERN" },
  { id: "cs9", title: "Manual QA" },
  { id: "cs10", title: "DevOps" },
]

export const Footer = () => {

  const { setSelectedIndustry } = useIndustries();
  const { scrollToSection } = useScroll();
  const navigate = useNavigate()
  const { handleRadioClick } = useRadio();

  const navigateToAboutUs = () => {
    navigate("/aboutUs")
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  const handleScroll = (sectionId) => {

    handleRadioClick(sectionId)
    setSelectedIndustry(sectionId)
    scrollToSection("scrollId")
  };

  return (

    <section className="footer-main-section">

      <div className="container text-center">
        <div className="row align-items-start">
          <div className="footer-top-section">
            <div className="col">
              {/* <div className="footer-first-logo">
                <img src={TaskCraftLogo} alt="" />
              </div> */}
            </div>
            <div className="col">
              <div className="footer-second-logo"></div>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col footer-margin mt mt-res">
            <div className="footer-left-heading address-margin-top">
              <span className="footer-aboutUs" onClick={navigateToAboutUs}> ABOUT US </span>
            </div>
            <div className="footer-left-heading footer-left-subheading">
              INDUSTRIES
            </div>
            <div className="footer-subcol-left">
              <div className="col footer-subcolumn-ul ">
                <ul>
                  {first_column.map((item) => (
                    <li className="center-listitems" key={item.id} onClick={(e) => {
                      e.preventDefault()
                      handleScroll(item.title)
                    }}>
                      <Link to="/" className="footer-listitems-parent-left">
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col footer-subcolumn-ul">
                <ul>
                  {second_column.map((item) => (
                    <li className="center-listitems" key={item.id} onClick={() => handleScroll(item.title)}>
                      <Link to=".." className="footer-listitems-parent-left">
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

            </div>
          </div>
          <div className="col footer-margin-center">
            <div className="footer-subcol-center">
              <div className="col">
                <div className="footer-left-heading">Models</div>
                <div className="footer-subcolumn-ul">
                  <ul>
                    {center_first_column.map((item) => (
                      <li className="center-listitems" key={item.id}>
                        <Link to=".." className="footer-listitems-parent-left">
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col">
                <div className="footer-left-heading">
                  Hire dedicated remote <br /> developers in India
                </div>
                <div className="footer-subcolumn-ul footer-hire-dev">
                  <ul>
                    {center_second_column.map((item) => (
                      <li className="center-listitems" key={item.id}>
                        <Link to=".." className="footer-listitems-parent-left">
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <ul>
                    {center_third_column.map((item) => (
                      <li className="center-listitems" key={item.id}>
                        <Link to=".." className="footer-listitems-parent-left">
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col footer-contactUs">
            <div className="contact-col">
              <div className="footer-left-heading  ml">
                Contact Us
              </div>
              <div className="footer-address">
                <div className="for-add-text add">Address : Second Floor, Sky Space, 290-291, PU-4, Vijay Nagar, Indore </div>
              </div>
              <div className="footer-column-right">

                <div className="skype-grid">

                  <div className="skype-num div-1">
                    <Link to="#">Skype :</Link>
                  </div>


                  <div className="skype-num div-4">
                    <Link to="#">+1 352 448 6955</Link>
                  </div>
                </div>

                <div className="email-grid">

                  <div className="phone-num div-1">
                    <Link to="#">Email :</Link>
                  </div>

                  <div className="footer-hr-email div-2">
                    <Link to="hr@taskcraft.in">sales@taskcraft.in</Link>
                  </div>

                  <div className="footer-hr-email div-3">
                    <Link to="hr@taskcraft.in">hr@taskcraft.in</Link>
                  </div>



                  {/* <div className="email-grid pmt"> */}
                  <div className="phone-num div-4">
                    <Link to="#">Phone Number:</Link>
                  </div>
                  <div className="footer-hr-email div-5">
                    <Link to="hr@taskcraft.in">+91-7880001860</Link>
                  </div>
                  <div className="footer-hr-email div-6">
                    <Link to="hr@taskcraft.in">+91-7880001859</Link>
                  </div>
                </div>
                {/* </div> */}

              </div>

              <div className="footer-third-column-last pmt">
                <div className="footer-element-divider-column-second">
                  <div className="icon-value">
                    <Link
                      to="https://twitter.com/HRTeam40921298"
                      className="twitter-icon"
                    >
                      < RiTwitterXFill size={40} />
                    </Link>
                    <Link
                      to="https://www.instagram.com/taskcraft_technologies/"
                      className="instagram-icon"
                    >
                      <FaInstagramSquare size={40} />
                    </Link>
                    <Link
                      to="https://www.linkedin.com/company/79373521/admin/feed/posts/"
                      className="linkedin-icon"
                    >
                      <FaLinkedin size={40} />
                    </Link>
                    <Link
                      to="https://www.facebook.com/profile.php?id=100083284865373"
                      className="youtube-icon"
                    >
                      <FaFacebook size={40} />
                    </Link>
                    <Link
                      to="https://join.skype.com/invite/ub91skYE74bA"
                      className="youtube-icon"
                    >
                      <FaSkype size={40} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", marginTop: 20 }}>
        <img height={100} style={{ marginRight: 20 }} src={iso} alt="ISO 27001" />
        <img height={100} src={cmmi5} alt="CMMI Level 5" />
      </div>
      <div className="row align-items-center footer-end-content">
        <div className="end-content">
          <div className="copyright-text">
            © 2024 Taskcraft. All Rights Reserved.
          </div>
          <div className="privacy-text">
            <Link to="">Privacy Policy</Link>
            <Link to="">Sitemap</Link>
          </div>
        </div>
      </div>
    </section>
  );
};

