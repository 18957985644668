import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { Collapse } from "react-bootstrap";

import { routes } from "../../routes/routes";
import { Button } from "../../components/button";
import { SecondHoverContent } from "./secondHoverContent/SecondHoverContent";
import TaskCraftLogo from "../../assets/TaskCraft_logo.png";
import "./Header.css";

export const Header = () => {
  const navigate = useNavigate();
  const [color, setColor] = useState(false);
  const [show, setShow] = useState(false);
  const [isServicesAccordionOpen, setServicesAccordionOpen] = useState(false);

  const navRef = useRef();

  const headerNavigateHandler = () => {
    navigate("/staffAugmentation");
  };

  const services = [
    {
      id: "s1",
      label: "Software Development",
      subTitle: [
        {
          id: "st1",
          title: "Custom Application Development",
          to: "customApplicationDevelopment",
        },
        {
          id: "st2",
          title: "Custom Mobile Development",
          to: "customMobileDevelopment",
        },
        {
          id: "st3",
          title: "Web Development",
          to: "webDevelopment",
        },
        {
          id: "st4",
          title: "Product Engineering",
          to: "productEngineering",
        },
      ],
    },
    {
      id: "s2",
      label: "Infrastructure Management",
      subTitle: [
        {
          id: "st5",
          title: "Cloud & Infrastructure",
          to: "cloudAndInfrastructure",
        },
        {
          id: "st6",
          title: "DevOps Solutions",
          to: "devOpsSolutions",
        },
        {
          id: "st7",
          title: "Salesforce Development",
          to: "salesforceDevelopment",
        },
        {
          id: "st8",
          title: "Product Engineering",
          to: "managedAWSService",
        },
      ],
    },
    {
      id: "s3",
      label: "Enterprise Solutions",
      subTitle: [
        {
          id: "st13",
          title: "API Development",
          to: "APIDevelopment",
        },
        {
          id: "st14",
          title: "Testing Solutions",
          to: "testingSolutions",
        },
        {
          id: "st15",
          title: "Database Solutions",
          to: "databaseSolutions",
        },
        {
          id: "st16",
          title: "back-endSolutions",
          to: "staffAugumentation",
        },
      ],
    },
    {
      id: "s4",
      label: "Application Services",
      subTitle: [
        {
          id: "st9",
          title: "ERP Solutions",
          to: "ERPSolutions",
        },
        {
          id: "st10",
          title: "On-demand Solutions",
          to: "onDemandSolutions",
        },
        {
          id: "st11",
          title: "Mobility Solutions",
          to: "mobilitySolutions",
        },
        {
          id: "st12",
          title: "Back-end Solutions",
          to: "back-endSolutions",
        },
      ],
    },
  ];

  const heading = [
    { label: "Services", value: "Services" },
    { label: "About Us", value: "About Us" },
    { label: "Contact Us", value: "Contact Us" },
    { label: "Careers", value: "Careers" },
  ];

  const showNavbar = () => {
    navRef.current?.classList?.toggle("responsive_nav");
    navRef.current?.classList?.remove("close_responsive_nav");
  };

  const closeNavbar = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    navRef.current?.classList?.toggle("close_responsive_nav");
    navRef.current?.classList?.remove("responsive_nav");
    
  };

  const toggleServicesAccordion = () => {
    setServicesAccordionOpen(!isServicesAccordionOpen);
  };
  const handleChangeColor = () => {
    setColor(window.scrollY >= 80);
  };

  const handleLogoClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  window.addEventListener("scroll", handleChangeColor);

  return (
    <div>
      <header className={color ? "header-bg" : "header-main-div"}>
        <div className="header-sub-div">
          <Link
            to={routes.general.home}
            onClick={handleLogoClick}
            className={color ? "banner-container-bg" : "banner-container"}
          >
            <img
              className="banner-tagline"
              src={TaskCraftLogo}
              alt="taskCraft logo"
            ></img>
          </Link>
          <nav ref={navRef}>
            <div id="header-navbar-one"></div>
            <div className="services-toggle-button">
              <Button
                className={
                  color ? "accordions-main-button" : "accordions-button-bg"
                }
              >
                <Link
                  to={routes.general.aboutUs}
                  className="heading-other-names"
                  onClick={closeNavbar}
                >
                  {heading[1].label}
                </Link>
              </Button>
            </div>
            <div>
              <Link
                onClick={handleLogoClick}
                to={routes.general.aboutUs}
                className={
                  color ? "heading-first-name-bg" : "heading-first-name"
                }
              >
                {heading[1].label}
              </Link>
            </div>

            <div className="services-toggle-button">
              <Button
                className={
                  color ? "accordions-main-button" : "accordions-button-bg"
                }
                onClick={toggleServicesAccordion}
              >
                <span className="heading-other-names">
                  {heading[0].label}
                  <IoIosArrowDown />
                </span>
              </Button>
            </div>
            <div
              className={color ? "main-navbar" : "main-navbar-bg"}
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              <span
                className={
                  color ? "heading-first-name-bg" : "heading-first-name"
                }
              >
                {heading[0].label}
              </span>
              {show ? <SecondHoverContent setShow={setShow} /> : null}
            </div>
            <Collapse in={isServicesAccordionOpen}>
              <div className="accordions-body">
                {services.map((item) => (
                  <>
                    <Link to={item.to} className="link-tags" key={item.id}>
                      <option
                        className="industry-options"
                        onClick={closeNavbar}
                      >
                        {item.label}
                      </option>
                    </Link>
                    <ul className="mobile-header">
                      {item.subTitle.map((el) => (
                        <li key={el.id}>
                          <Link to={el.to} key={el.id} onClick={closeNavbar}>
                            {el.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                ))}
              </div>
            </Collapse>

            <div className="services-toggle-button">
              <Button
                className={
                  color ? "accordions-main-button" : "accordions-button-bg"
                }
              >
                <Link
                  to={routes.general.contactUs}
                  className="heading-other-names"
                  onClick={closeNavbar}
                >
                  {heading[2].label}
                </Link>
              </Button>
            </div>

            <div>
              <Link
               onClick={handleLogoClick}
                to={routes.general.contactUs}
                className={
                  color ? "heading-first-name-bg" : "heading-first-name"
                }
              >
                {heading[2].label}
              </Link>
            </div>

            <div>
              <Link
                to={routes.general.careers}
                className={
                  color ? "heading-first-name-bg" : "heading-first-name"
                }
              >
                {heading[3].label}
              </Link>
            </div>

            <Button
              className={color ? "hire-button-bg" : "hire-button"}
              onClick={headerNavigateHandler}
            >
              HIRE TEAM
              <FaLongArrowAltRight />
            </Button>
          </nav>
        </div>
        <Button
          className={color ? "nav-btn" : "nav-btn-bg"}
          onClick={showNavbar}
        >
          <FaBars />
        </Button>

        

        

        
      </header>
    </div>
  );
};
