import React from "react";

import SD from "../../../assets/images/software-engineer.png"
import ES from "../../../assets/images/outsourcing.png"
import IM from "../../../assets/images/data-management.png"
import AS from "../../../assets/images/Application-services.png"
import { Button } from "../../../components/button";
import "./ServiceSectionStyle.css";
import { Link } from "react-router-dom";
import { routes } from "../../../routes/routes";

export default function ServiceSection() {
  return (
    <>
      <section
        id="ourservice"
        className="service-Section-Header"
        data-aos="fade-up"
        data-aos-duration="4000"
      >
        <h4 className="text-Services">Our Services</h4>
        <div className="service-Divider">
          <div className="service-Divider-Separator"> </div>
        </div>
        <p className="sub-text-services">
        Advancing beyond merely communicating to ‘Connecting’ people
        </p>

        <div className="services-main-card-section">
          <div className="content-second-column">
            <div className="row second-column-first-rows ">
              <div className="col-4 whole-first-column">
                <div className="container-first-column">
                  <div className="container-first-column-gradient">
                    <div className="container-first-column-content">
                      <b>
                        <h3>Our Services</h3>
                      </b>
                      <p className="services-section-content">
                      Redefining Customer Satisfaction through Our Services
                      </p>
                      {/* <Button className="first-column-explore-btn">
                        <b>Explore All Our Services</b>
                      </Button> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col columns-of-second-section">
                <div className="col container-second-column">
                  <div className="service-section-tabs-icon">
                    <img src={SD} alt="software development"></img>
                  </div>

                  <div className="col service-section-tabs-content">
                    <h5>Software Development</h5>
                    <Link to={routes.hireDevelopersRoutes.customApplicationDevelopment}><span>Custom Application Development</span></Link>
                    <Link to={routes.hireDevelopersRoutes.customMobileDevelopment}><span>Custom Mobile Development</span></Link>
                    <Link to={routes.hireDevelopersRoutes.webDevelopment}><span>Web Development </span></Link>
                    <Link to={routes.hireDevelopersRoutes.productEngineering}><span>Product Engineering </span></Link>
                  </div>
                </div>
                <div className="col container-second-column">
                  <div className="service-section-tabs-icon">
                    <img src={IM} alt="infrastructure management"></img>
                  </div>
                  <div className="col service-section-tabs-content">
                    <h5>Infrastructure Management</h5>
                    <Link to={routes.hireDevelopersRoutes.cloudAndInfrastructure}><span>Cloud & Infrastructure</span></Link>
                    <Link to={routes.hireDevelopersRoutes.devOpsSolutions}><span>DevOps Solutions</span></Link>
                    <Link to={routes.hireDevelopersRoutes.salesforceDevelopment}><span>Salesforce Development</span></Link>
                    <Link to={routes.hireDevelopersRoutes.managedAWSService}><span>Managed AWS Services</span></Link>
                  </div>
                </div>
              </div>

              <div className="col columns-of-second-section">
                <div className="col container-second-column">
                  <div className="service-section-tabs-icon">
                    <img src={ES} alt="enterprises solution"></img>
                  </div>
                  <div className="col service-section-tabs-content">
                    <h5>Enterprise Solutions</h5>
                    <Link to={routes.hireDevelopersRoutes.ERPSolutions}><span>ERP Solutions</span></Link>
                    <Link to={routes.hireDevelopersRoutes.onDemandSolutions}><span>On-demand Solutions</span></Link>
                    <Link to={routes.hireDevelopersRoutes.mobilitySolutions}><span>Mobility Solutions</span></Link>
                    <Link to={routes.hireDevelopersRoutes.staffAugmentation}><span>Staff Augmentation</span></Link>
                  </div>
                </div>
                <div className="col container-second-column">
                  <div className="service-section-tabs-icon">
                    <img src={AS} alt="Application services"></img>
                  </div>
                  <div className="col service-section-tabs-content">
                    <h5>Application Services</h5>
                    <Link to={routes.hireDevelopersRoutes.APIDevelopment}><span>API Development</span></Link>
                    <Link to={routes.hireDevelopersRoutes.testingSolutions}><span>Testing Solutions</span></Link>
                    <Link to={routes.hireDevelopersRoutes.databaseSolutions}> <span>Database Solutions</span></Link>
                    <Link to={routes.hireDevelopersRoutes.backEndSolutions}><span>Back-End Solutions </span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
