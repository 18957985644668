import React from "react";

import { Button } from "../../../components/button";
import "./AboutUsTopSection.css";
import cup from "../../../assets/Untitled design (1) (1).png";
import { useNavigate } from "react-router-dom";


export const AboutUsTopSection = () => {

  const navigate = useNavigate()

  const handleNavigate = () => {
      navigate("/staffAugmentation")
  }

  return (
    <div className="about-us-main-div">
      <div className="container">
        <div className="row main-content-about-us-row">
          <div className="col-8">
            <div className="first-column-first-div">
              <h4 className="about-us-heading">About Us</h4>
              <h1 className="about-us-content">
                Setting standards in moving towards a greater future for
                technology
              </h1>
              <Button className="about-us-join-us-button" onClick={handleNavigate}>Join Us</Button>
            </div>
          </div>
          <div className="col-4">
            <div className="about-us-second-main">
              <img className="about-us-image" src={cup} alt="this is a cup" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
