import React, { useEffect,  useRef,  useState } from "react";

import industriesContent from "../../../constants/constants.json";
import "./IndustriesWeServe.css";
import { useIndustries } from "../../../context/IndustriesContext";
import { useRadio } from '../../../context/RadioContext';

export const IndustriesWeServe = () => {
  return (
    <section className="industries-main-section"  id="scrollId"   >
      <div className="container">
        <div className="row align-items-start">
          <div className="industries-main-title">
            <h2>
              <strong>Industries we serve</strong>
            </h2>
          </div>
          <div className="industries-first-para">
            <p>
              Apart from the technical skills, we bring tremendous value to our
              clients with domain knowledge.
              <br />
              Over the years, we have built software solutions for diversified
              industries
            </p>
          </div>
        </div>
        <IndustriesWeServeForm />
      </div>
    </section>
  );
};

export const IndustriesWeServeForm = () => {
  const [inputValue, setInputValue] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const radioInputRef = useRef(null);
  const { selectedIndustry } = useIndustries();
  const { selectedRadioId, handleRadioClick } = useRadio();

 useEffect(() => {
   if(selectedIndustry){  
    industriesContentHandler(selectedIndustry);
   }
 },[selectedIndustry])

 useEffect(() => {
  handleRadioClick("Healthcare");
  industriesContentHandler(selectedIndustry);
 },[])

  const industriesContentHandler = (val) => {
    setInputValue(val);
    setButtonClicked(true);
    handleRadioClick(val)
  };

  return (
    <div className="row align-items-start">
      
      <div className="col col-lg-4">
        <form className="industries-boxed">
          <input
            type="radio"
            id="healthcare"
            name="skills"
            value="Healthcare"
            checked={selectedRadioId === 'Healthcare'}
            onClick={(event) => industriesContentHandler(event.target.value)}
            defaultChecked
          />
          <label for="healthcare" >Healthcare</label>
          <br />
          <input
            type="radio"
            id="energyandgas"
            name="skills"
            value="Energy & Gas"
            ref={radioInputRef}
            checked={selectedRadioId === "Energy & Gas"}
            onClick={(event) => industriesContentHandler(event.target.value)}
          />
          <label for="energyandgas" id="Energy & Gas">Energy & Gas</label>
          <br />
          <input
            type="radio"
            id="educationandelearning"
            name="skills"
            value="EducationAndELearning"
            ref={radioInputRef}
            
            onClick={(event) => industriesContentHandler(event.target.value)}
          />
          <label for="educationandelearning" >Education & e-Learning</label>
          <br />
          <input
            type="radio"
            id="E-commerce"
            name="skills"
            value="E-commerce"
            checked={selectedRadioId === "E-commerce"}
            onClick={(event) => industriesContentHandler(event.target.value)}
          />
          <label for="E-commerce" id="E-commerce">E-Commerce</label>
          <br />
          <input
            type="radio"
            id="Transportation & Logistics"
            name="skills"
            value="Transportation & Logistics"
            checked={selectedRadioId === "Transportation & Logistics"}
            onClick={(event) => industriesContentHandler(event.target.value)}
          />
          <label for="Transportation & Logistics" id="Transportation & Logistics">
            Transportation & Logistics
          </label>
          <br />
          <input
            type="radio"
            id="Sports"
            name="skills"
            value="Sports"
            checked={selectedRadioId === "Sports"}
            onClick={(event) => industriesContentHandler(event.target.value)}
          />
          <label for="Sports" id="Sports">Sports</label>
          <br />
          <input
            type="radio"
            id="Fintech"
            name="skills"
            value="Fintech"
            checked={selectedRadioId === "Fintech"}
            onClick={(event) => industriesContentHandler(event.target.value)}
          />
          <label for="Fintech" id="Fintech">Fintech</label>
          <br />
          <input
            type="radio"
            id="On-Demand"
            name="skills"
            value="On-Demand"
            checked={selectedRadioId === "On-Demand"}
            onClick={(event) => industriesContentHandler(event.target.value)}
          />
          <label for="On-Demand" id="On-Demand">On-Demand</label>
          <br />
          <input
            type="radio"
            id="Marketing & Advertising"
            name="skills"
            value="Marketing & Advertising"
            checked={selectedRadioId === "Marketing & Advertising"}
            onClick={(event) => industriesContentHandler(event.target.value)}
          />
          <label for="Marketing & Advertising" id="Marketing & Advertising">Marketing & Advertising</label>
          <br />
        </form>
      </div>
      <div className="col">
        <IndustriesWeServeContent
          inputValue={inputValue}
          buttonClicked={buttonClicked}
        />
      </div>
    </div>
  );
};

export const IndustriesWeServeContent = ({ inputValue, buttonClicked }) => {
  const [IndustriesData, setIndustriesData] = useState(industriesContent[1][0]);

  useEffect(() => {
    if (buttonClicked) {
      const content = industriesContent[1].find(
        (item) => item?.id === inputValue
      );
      setIndustriesData(content);
    }
  }, [inputValue, buttonClicked]);


  return (
    <div>
      <div className="row align-items-start" >
        <div className="industries-sub-title">
          <h2>
            <strong>{IndustriesData?.title}</strong>
          </h2>
        </div>
      </div>
      <div className="row align-items-start">
        <div className="industries-sub-para">
          <p>{IndustriesData?.body}</p>
        </div>
      </div>
      <div className="row align-items-start">
        <ul className="industries-sub-content">
          {IndustriesData?.content?.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
