import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import "./TechiesPractice.css";
import RorPng from '../../../assets/RoR.png'

const react_app = [
  {
    id: "r1",
    title: "React Native",
    url: "https://graffersid.com/wp-content/uploads/2023/02/react_border.png",
  },
  {
  id: "r2",
  title: "React JS",
  url: "https://graffersid.com/wp-content/uploads/2023/02/react_border.png",
},
];

const web_frontend = [
  {
    id: "w1",
    title: "React JS",
    url: "https://graffersid.com/wp-content/uploads/2023/02/react_border.png",
  },
  {
    id: "w2",
    title: "Angular",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171867.png",
  },
  {
    id: "w3",
    title: "Redux",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171868.png",
  },
  {
    id: "w4",
    title: "Type Script",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171869.png",
  },
  {
    id: "w5",
    title: "BootStrap",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171873.png",
  },
  {
    id: "w6",
    title: "HTML",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171874.png",
  },
  {
    id: "w7",
    title: "CSS",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171882.png",
  },
];

const backend = [
  {
    id: "b1",
    title: "NodeJS",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171870.png",
  },
  {
    id: "b2",
    title: "Express.JS",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171871.png",
  },
  {
    id: "b3",
    title: "NestJS",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171872.png",
  },
  {
    id: "b4",
    title: "Ruby on Rails",
    url: RorPng,
  },
];

const database = [
  {
    id: "d1",
    title: "MongoDB",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171882-1.png",
  },
  {
    id: "d2",
    title: "PostgreSQL",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171878.png",
  },
  {
    id: "d3",
    title: "MySQL",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171889.png",
  },
  {
    id: "d4",
    title: "MariaDB",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171888.png",
  },
];

const devops = [
  {
    id: "de1",
    title: "AWS",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171879.png",
  },
  {
    id: "de2",
    title: "Google Cloud",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171880.png",
  },
  {
    id: "de3",
    title: "Azure",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171881.png",
  },
  {
    id: "de4",
    title: "Jenkins",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171883.png",
  },
  {
    id: "de5",
    title: "Docker",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171887.png",
  },
  {
    id: "de6",
    title: "Ansible",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171886.png",
  },
  {
    id: "de7",
    title: "Kubernetes",
    url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171884.png",
  },
];

export const TechiesPractice = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <section>
      <div className="tech-heading">
        <h3 className="element-heading">
          TECHNOLOGY STACK
        </h3>
        <div className="element-divider">
          <span className="element-divider-separator"></span>
        </div>
        <p className="element-heading-para">We transform your vision into complete tech solutions using the latest and trendiest technologies and tools</p>
      </div>
      <div className="card-container">
        <div className="techies-card" data-aos="zoom-in">
          <div className="card-content">
            <div className="sub-title">
              <p>Mobile App</p>
            </div>
            <ul className="image-row card-one">
              {react_app.map((data) => (
                <li className="image-item" key={data.id}>
                  <a href="/">
                    <img src={data.url} alt="" />
                    <p className="card-content-para">{data.title}</p>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="techies-card" data-aos="zoom-in">
          <div className="card-content">
            <div className="sub-title">
              <p>Web FrontEnd</p>
            </div>
            <ul className="image-row">
              {web_frontend.map((data) => (
                <li className="image-item" key={data.id}>
                  <a href="/">
                    <img src={data.url} alt="" />
                    <p className="card-content-para">{data.title}</p>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="techies-card" data-aos="zoom-in">
          <div className="card-content">
            <div className="sub-title">
              <p>BackEnd</p>
            </div>
          </div>
          <ul className="image-row">
            {backend.map((data) => (
              <li className="image-item" key={data.id}>
                <a href="/">
                  <img src={data.url} alt="" />
                  <p className="card-content-para">{data.title}</p>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="card-container">
        <div className="techies-card" data-aos="zoom-in">
          <div className="card-content">
            <div className="sub-title">
              <p>Database</p>
            </div>
            <ul className="image-row">
              {database.map((data) => (
                <li className="image-item" key={data.id}>
                  <a href="/">
                    <img src={data.url} alt="" />
                    <p className="card-content-para">{data.title}</p>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={"techies-card card-right"} data-aos="zoom-in">
          <div className="card-content">
            <div className="sub-title">
              <p>DEvops</p>
            </div>
            <ul className="image-row card-fifth">
              {devops.map((data) => (
                <li className="image-item card-fifth" key={data.id}>
                  <a href="/">
                    <img src={data.url} alt="" />
                    <p className="card-content-para">{data.title}</p>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
