// import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import { API, request } from "./utils";
import { Home } from "./screens/home";
import { routes } from "./routes/routes";
import { HireDevelopers } from "./screens";
import { Header } from "./container/header";
import { ContactUs } from "./screens";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { AboutUs } from "./screens";
import { CareersForm } from "./screens/careers/CareersForm";
import { ScrollProvider } from './context/ScrollContext';
import { IndustriesProvider } from "./context/IndustriesContext";
import { RadioProvider } from "./context/RadioContext";

function App() {
  return (
    <BrowserRouter>
    {/* <ScrollToTop />   */}
    <RadioProvider>
     <ScrollProvider> 
      <IndustriesProvider>
      <Header />
       
      <Routes>
        <Route path={routes.general.home} element={<Home />} />
        <Route
          path={routes.general.hireDevelopers}
          element={
            <>
              <HireDevelopers />
            </>
          }
        />
        <Route
          path={routes.general.contactUs}
          element={
            <>
              <ContactUs />
            </>
          }
        />
        <Route
          path={routes.general.careers}
          element={
            <>
              <CareersForm/>
            </>
          }
        />
        <Route path={routes.general.aboutUs} element={<AboutUs />} />
      </Routes>
      </IndustriesProvider>
       </ScrollProvider> 
      </RadioProvider>
    </BrowserRouter>
  );
}

export default App;
