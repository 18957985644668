import React from "react";

import { ClientSection } from "./clientSection";
// import { Life } from "./lifeAtTaskCraft/Life";
import { ServiceSection } from "./serviceSection";
// import { HomeTestimonials } from "./homeTestimonials/HomeTestimonials";
import { HomeTop } from "./homeTop";
import { ContactUs } from "../contactUs";
// import { HomeQASection } from "./homeQASection";
import { TechiesPractice } from "./techiesPractice";
import { TrustedTechSection } from "./trustedTechPartnerSection";
// import { CustomerProtectionActualContent } from "./customerProtection";
import { Footer } from "../../container/footerSection";
import { IndustriesWeServe } from "./industriesWeServe";
import "./Home.css";

export const Home = () => {
  return (
    <div className="Home-main-div">
      <HomeTop />
      <ServiceSection />
      {/* <HomeQASection /> */}
      {/* <HomeTestimonials /> */}
      <TechiesPractice />
      <TrustedTechSection />
      <ClientSection />
      {/* <CustomerProtectionActualContent /> */}
      <IndustriesWeServe />
      {/* <Life /> */}
      <ContactUs />
      <Footer />
    </div>
  );
};
