export const routes = {
  general: {
    home: "/",
    hireDevelopers: ":contentId",
    contactUs: "contactus",
    aboutUs: "aboutus",
    careers: "careers",
  },
  hireDevelopersRoutes: {
    customApplicationDevelopment: "customApplicationDevelopment",
    customMobileDevelopment: "customMobileDevelopment",
    webDevelopment: "webDevelopment",
    productEngineering: "productEngineering",
    cloudAndInfrastructure: "cloudAndInfrastructure",
    devOpsSolutions: "devOpsSolutions",
    salesforceDevelopment: "salesforceDevelopment",
    managedAWSService: "managedAWSService",
    ERPSolutions: "ERPSolutions",
    onDemandSolutions: "onDemandSolutions",
    mobilitySolutions: "mobilitySolutions",
    staffAugmentation: "staffAugmentation",
    APIDevelopment: "APIDevelopment",
    testingSolutions: "testingSolutions",
    databaseSolutions: "databaseSolutions",
    backEndSolutions: "back-endSolutions",
  },
};
