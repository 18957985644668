import React from "react";
import { Link } from "react-router-dom";

import FirstImage from "../../../assets/trustedTechOne.svg";
import HyperLocal from "../../../assets/images/hyperLocal.svg";
import Saas from "../../../assets/images/hyperLocal.svg";
import ApplicationImage from "../../../assets/images/applicationImage.jpeg";
import Education from "../../../assets/images/Education.svg";
import Sale from "../../../assets/images/Sale.svg";
import Finance from "../../../assets/images/Finance.svg";
import SecondImage from "../../../assets/images/TaskCraft_c.png";
import "./trustedTechStyle.css";

export default function TrustedTechSection() {
  return (
    <section className="main-container">
      <div className="first-section" data-aos="fade-right">
        <div className="three-icon-box-group">
          <div className="">
            <Link to="">
              <img
                decoding="async"
                width="140"
                height="130"
                src={SecondImage}
                className="attachment-large wp-image-20176"
                alt=""
                loading="lazy"
                srcSet=""
                sizes="(max-width: 155px) 100vw, 155px"
              />
            </Link>
          </div>
          <div className="trust-icon-box">
            <div className="trust-icon-box-contain">
              <figure className="image-box-img">
                <Link to="">
                  <img
                    decoding="async"
                    width="54"
                    height="54"
                    src={Education}
                    className="attachment-full size-full wp-image-20703"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </figure>
              <div className="image-box-text">
                <h3 className="image-box-title">
                  <Link to="">Education</Link>
                </h3>
              </div>
            </div>
          </div>
          <div className="trust-icon-box">
            <div className="trust-icon-box-contain">
              <figure className="image-box-img">
                <Link to="">
                  <img
                    decoding="async"
                    width="54"
                    height="54"
                    src={Sale}
                    className="attachment-full size-full wp-image-20704"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </figure>
              <div className="image-box-text">
                <h3 className="image-box-title">
                  <Link to="">MARKET PLACE</Link>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="three-icon-box-group top-gap">
          <div className="trust-icon-box">
            <div className="trust-icon-box-contain">
              <figure className="image-box-img">
                <Link to="">
                  <img
                    decoding="async"
                    width="54"
                    height="54"
                    src={Finance}
                    className="attachment-full size-full wp-image-20705"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </figure>
              <div className="image-box-text">
                <h3 className="image-box-title">
                  <Link to=".">FINancial technology</Link>
                </h3>
              </div>
            </div>
          </div>
          <div className="trust-icon-box">
            <div className="trust-icon-box-contain">
              <figure className="image-box-img">
                <Link to="">
                  <img
                    decoding="async"
                    width="54"
                    height="54"
                    src={ApplicationImage}
                    className="attachment-full size-full wp-image-20706"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </figure>
              <div className="image-box-text">
                <h3 className="image-box-title">
                  <Link to="">Applications</Link>
                </h3>
              </div>
            </div>
          </div>
          <div className="trust-icon-box">
            <div className="trust-icon-box-contain">
              <figure className="image-box-img">
                <Link to=".">
                  <img
                    decoding="async"
                    width="54"
                    height="54"
                    src={HyperLocal}
                    className="attachment-full size-full wp-image-20707"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </figure>
              <div className="image-box-text">
                <h3 className="image-box-title">
                  <Link to="">HYPER LOCAL</Link>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="three-icon-box-group top-gap">
          <div className="box-start">
            <div className="">
              <img
                decoding="async"
                width="34"
                height="33"
                src="//graffersid.com/wp-content/uploads/2023/02/Vector-264.png"
                className="attachment-large size-large wp-image-20242"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
          <div className="trust-icon-box">
            <div className="trust-icon-box-contain">
              <figure className="image-box-img">
                <Link to=".">
                  <img
                    decoding="async"
                    width="54"
                    height="54"
                    src={Saas}
                    className="attachment-full size-full wp-image-20708"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </figure>
              <div className="image-box-text">
                <h3 className="image-box-title">
                  <Link to=".">Software As A Service</Link>
                </h3>
              </div>
            </div>
          </div>
          <div className="trust-icon-box">
            <div className="trust-icon-box-contain">
              <figure className="image-box-img">
                <Link to="//graffersid.com/hire-ecommerce-developers/">
                  <img
                    decoding="async"
                    width="54"
                    height="54"
                    src={FirstImage}
                    className="attachment-full size-full wp-image-20709"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </figure>
              <div className="image-box-text">
                <h3 className="image-box-title">
                  <Link to=".">HEALTH TECHNOLOGY</Link>
                </h3>
              </div>
            </div>
          </div>
          <div className="new-project-container">
            <div className="new-project-button-wrapper">
              <Link to="contactus" role="button">
                <span className="">
                  <span className="new-project-text image-box-title">
                    New Projects
                  </span>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* second section */}
      <div className="second-section" data-aos="fade-left">
        <div className="trust-title">
          <h3 className="">
            Your Trusted Tech <br /> Partner
          </h3>
          <div className="line-divider">
            <div className="line-divider-separator"> </div>
          </div>

          <div className="trust-paragraph">
            <p>
              With digital transformation touching all aspects of every
              industry, software testing has become vital for uninterrupted
              business processes and high availability. Our domain experts
              specialize in improving the robustness of industry-specific
              software to help enterprises accelerate their processes and
              mitigate risks.
            </p>
          </div>

          <div className="outside-border">
            <div className="first-half">
              <ul className="trusted-icon-list-items">
                <li className="trusted-icon-list-item">
                  <div className="trusted-list-long-text">
                    <p className="trusted-icon-list-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="12"
                        viewBox="0 0 18 15"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clip-rule="evenodd"
                          d="M0.773438 7.22668L3.91113 7.18568C5.12305 7.87582 6.20768 8.76959 7.14152 9.89978C9.55705 5.94342 12.3323 2.7018 15.3828 0H17.7734C13.5082 4.68067 10.0274 9.69205 7.2439 15C5.75529 11.8472 3.67318 9.19324 0.773438 7.22668Z"
                          fill="#01A601"
                        ></path>
                      </svg>
                    </p>
                    <p className="trusted-icon-list-text">No freelancer</p>
                  </div>
                </li>
                <li className="trusted-icon-list-item">
                  <div className="trusted-list-long-text">
                    <p className="trusted-icon-list-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="12"
                        viewBox="0 0 18 15"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clip-rule="evenodd"
                          d="M0.773438 7.22668L3.91113 7.18568C5.12305 7.87582 6.20768 8.76959 7.14152 9.89978C9.55705 5.94342 12.3323 2.7018 15.3828 0H17.7734C13.5082 4.68067 10.0274 9.69205 7.2439 15C5.75529 11.8472 3.67318 9.19324 0.773438 7.22668Z"
                          fill="#01A601"
                        ></path>
                      </svg>
                    </p>
                    <p className="trusted-icon-list-text">
                      Availability of project-ready engineers
                    </p>
                  </div>
                </li>
                <li className="trusted-icon-list-item">
                  <div className="trusted-list-long-text">
                    <p className="trusted-icon-list-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="12"
                        viewBox="0 0 18 15"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clip-rule="evenodd"
                          d="M0.773438 7.22668L3.91113 7.18568C5.12305 7.87582 6.20768 8.76959 7.14152 9.89978C9.55705 5.94342 12.3323 2.7018 15.3828 0H17.7734C13.5082 4.68067 10.0274 9.69205 7.2439 15C5.75529 11.8472 3.67318 9.19324 0.773438 7.22668Z"
                          fill="#01A601"
                        ></path>
                      </svg>
                    </p>
                    <p className="trusted-icon-list-text">
                      Flexible Engagement Options
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="first-half">
              <ul className="trusted-icon-list-items">
                <li className="trusted-icon-list-item">
                  <div className="trusted-list-long-text">
                    <p className="trusted-icon-list-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="12"
                        viewBox="0 0 18 15"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clip-rule="evenodd"
                          d="M0.773438 7.22668L3.91113 7.18568C5.12305 7.87582 6.20768 8.76959 7.14152 9.89978C9.55705 5.94342 12.3323 2.7018 15.3828 0H17.7734C13.5082 4.68067 10.0274 9.69205 7.2439 15C5.75529 11.8472 3.67318 9.19324 0.773438 7.22668Z"
                          fill="#01A601"
                        ></path>
                      </svg>
                    </p>
                    <p className="trusted-icon-list-text">
                      Transparent approach
                    </p>
                  </div>
                </li>
                <li className="trusted-icon-list-item">
                  <div className="trusted-list-long-text">
                    <p className="trusted-icon-list-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="12"
                        viewBox="0 0 18 15"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clip-rule="evenodd"
                          d="M0.773438 7.22668L3.91113 7.18568C5.12305 7.87582 6.20768 8.76959 7.14152 9.89978C9.55705 5.94342 12.3323 2.7018 15.3828 0H17.7734C13.5082 4.68067 10.0274 9.69205 7.2439 15C5.75529 11.8472 3.67318 9.19324 0.773438 7.22668Z"
                          fill="#01A601"
                        ></path>
                      </svg>
                    </p>
                    <p className="trusted-icon-list-text">Scalability</p>
                  </div>
                </li>
                <li className="trusted-icon-list-item">
                  <div className="trusted-list-long-text">
                    <p className="trusted-icon-list-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="12"
                        viewBox="0 0 18 15"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clip-rule="evenodd"
                          d="M0.773438 7.22668L3.91113 7.18568C5.12305 7.87582 6.20768 8.76959 7.14152 9.89978C9.55705 5.94342 12.3323 2.7018 15.3828 0H17.7734C13.5082 4.68067 10.0274 9.69205 7.2439 15C5.75529 11.8472 3.67318 9.19324 0.773438 7.22668Z"
                          fill="#01A601"
                        ></path>
                      </svg>
                    </p>
                    <p className="trusted-icon-list-text">
                      Availability of engineers across various technologies
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="trusted-Hire-Developer-Button">
            <div className="hire-Button">
              <Link to="staffAugumentation">HIRE TEAM</Link>
              
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="10"
                  viewBox="0 0 16 10"
                  fill="none"
                >
                  <path
                    d="M12.01 3.84843H0V6.00259H12.01V9.23384L16 4.92551L12.01 0.617188V3.84843Z"
                    fill="#f28c04"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
