import React from "react";

import MessageIcon from "../../../assets/images/messageicon.png";
import FingerTipIcon from "../../../assets/images/fingertipicon.png";
import settings from "../../../assets/setting.png";
import "./OurUSP.css";

export const OurUSP = () => {
  return (
    <section className="usp-main-section">
      <div className="container text-center">
        <div className="row align-items-start">
          <div className="usp-title">
            <h1>TASKCRAFT USP</h1>
          </div>
          <div className="usp-subtitle">
          Our ability to blend creativity, Innovation, and industry best practices to create a niche that drives excellence and delivers remarkable outcomes for our clients.
          </div>
        </div>
        <div className="row align-items-start">
          <div className="col">
            <div className="usp-card" data-aos="zoom-in">
              <div className="col">
                <div className="usp-card-title">Seamless Communication</div>
                <div className="usp-card-content">
                  We have a 24/7 active communication channel to ensure that
                  every query is responded on time. We aim to keep our
                  communications as seamless as possible.
                </div>
              </div>
              <div className="col">
                <div className="usp-card-image">
                  <img src={MessageIcon} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="usp-card" data-aos="zoom-in">
              <div className="col">
                <div className="usp-card-title">Technology Expertise</div>
                <div className="usp-card-content">
                  To cater to complex problems and requirements and provide
                  out-of-the-box solutions, we follow continuous technology
                  adaptation and innovation.
                </div>
              </div>
              <div className="col">
                <div className="usp-card-image">
                  <img src={FingerTipIcon} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-start">
          <div className="usp-card usp-card-third" data-aos="zoom-in">
            <div className="col">
              <div className="usp-card-title">Prompt Execution</div>
              <div className="usp-card-content">
                We aim to follow stringent measures to execute our services
                within the stipulated time frame and provide the desired outputs
                as quick as possible .
              </div>
            </div>
            <div className="col">
              <div className="usp-card-image">
                <img src={settings} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
