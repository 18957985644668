import React from "react";

import "./TechnologyIntegratingSection.css";

export const TechnologyIntegratingSection = () => {
  return (
    <div className="tech-integration-main-div">
      <div className="tech-integration-gradient">
        <div className="tech-integration-blur-gradient">
          <div className="tech-heading-div">
            <h1 className="tech-heading">
            Unleashing Technology's Boundless Potential
            </h1>
          </div>
          <div className="container">
            <div className="row main-content-tech-row">
              <div className="col tech-paragraph-main-div">
                <p className="tech-paragraph-content">
                  Welcome to TASKCRAFT, where software expertise meets
                  innovative tech-savvy solutions to shape a better tomorrow. We
                  are committed to helping you thrive in the fast-paced digital
                  world by delivering results that exceed expectations.
                </p>
                <p className="tech-paragraph-content">
                  With a proven track record of assisting clients across various
                  industries, TASKCRAFT is a reliable partner in your journey
                  toward success. We specialize in providing top-notch
                  enterprise software and IT services tailored to meet the
                  unique needs of large corporations.
                </p>
                <p className="tech-paragraph-content">
                  Our team at TASKCRAFT truly understands the potential that
                  technology holds and the power of game-changing ideas. We are
                  driven by the belief that digital transformation can bring
                  about revolutionary changes in the way businesses operate and
                  in the way individuals interact with technology. Our aim is to
                  turn technology into tangible business value, ensuring that
                  every solution we create is rooted in authenticity and
                  tailored to meet your specific objectives.{" "}
                </p>
                <p>
                  When you partner with TASKCRAFT, you can rest assured that you
                  are collaborating with a team of experts who truly care about
                  your success. We take the time to understand your business
                  inside-out, allowing us to craft tailor-made strategies that
                  align with your long-term goals.
                </p>
              </div>

              <div className="col tech-second-column"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
