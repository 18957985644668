export const API = {
  contactAPI: {
    contactUs: "/contacts",
    contactServices: "/contactservices",
  },
};
export const contact_API = 'https://taskcraft.onrender.com/contacts';
export const careers_API = 'https://taskcraft.onrender.com/hires';
// export const API2 = {
//   careersAPI: {
//     careers: "/hires",
    
//   },
// };

